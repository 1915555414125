import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { Security, ImplicitCallback, SecureRoute, Auth } from '@okta/okta-react';
import config from './config';
import { createBrowserHistory } from 'history';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const history = createBrowserHistory();
export const CustomAuth = new Auth({
  history,
  ...config.oidc.auth,
});

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Security auth={CustomAuth}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path={config.oidc.callbackPath} component={ImplicitCallback} />
              <SecureRoute path="/" name="Home" render={props => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </Security>
      </Router>
    );
  }
}

export default App;
