const TENANT_SERVICE_BASE_URL = getValue('REACT_APP_TENANT_SERVICE_BASE_URL');
const CLIENT_ID = getValue('REACT_APP_OIDC_CLIENT_ID');
const ISSUER = getValue('REACT_APP_OIDC_ISSUER');
const OKTA_TESTING_DISABLEHTTPSCHECK = getValue('OKTA_TESTING_DISABLEHTTPSCHECK', false);
const CALLBACK_PATH = getValue('REACT_APP_LOGIN_REDIRECT_PATH', '/implicit/callback');
const SCOPES = getValue('REACT_APP_OIDC_SCOPES', 'openid profile email tenant_svc').split(' ');

function getValue(key, defaultValue) {
  const value = window[key] || process.env[key];

  if (!value) {
    if (typeof defaultValue !== 'undefined') {
      return defaultValue;
    }

    throw new Error(`Missing required environment variable: ${key}`);
  }

  return value;
}

export default {
  oidc: {
    auth: {
      clientId: CLIENT_ID,
      issuer: ISSUER,
      redirectUri: window.location.origin + CALLBACK_PATH,
      scopes: SCOPES,
      pkce: true,
      disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
    callbackPath: CALLBACK_PATH,
  },
  tenantServiceBaseUrl: TENANT_SERVICE_BASE_URL,
};
